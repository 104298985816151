

















import {Component} from "vue-property-decorator";
import {TebligatAlanEntity} from "@/entity/TebligatAlanEntity";
import {TebligatAlanListResponseProvider} from "@/services/TebligatAlanService";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import TebligatAlanForm from "@/components/comps/forms/TebligatAlanForm.vue";

@Component({
    components: {TebligatAlanForm}
})
export default class TebligatAlanSelect extends ObjectInputMixin {
    dialog = false;
    varOlanListe: TebligatAlanEntity[] = [];
    yeniAlanSecimi: TebligatAlanEntity | null = null;

    get secilebilenAlanListesi(): TebligatAlanEntity[] {
        return this.varOlanListe.concat(
            (this.yeniAlanSecimi ? [this.yeniAlanSecimi] : [])
        ).concat(
            {
                alan: "Yeni Ekle", id: 0, isimGirilecek: false
            }
        )
    }

    mounted() {
        this.varOlanListe = TebligatAlanListResponseProvider().data;
    }

    alanSecildi() {
        console.log("bakalım buraya düşecekler mi:", this.localValue);
        if (this.localValue && this.localValue.id == 0) {
            this.dialog = true;
        } else {

            this.input();
        }
    }

    alanSecimSuccess(item: TebligatAlanEntity) {
        this.yeniAlanSecimi = item;
        this.localValue = item;
        this.input();
        this.dialog=false;
    }

    alanSecimCancel() {
        this.yeniAlanSecimi = null;
        this.value = null;
        this.dialog=false;
    }

}


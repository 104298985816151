































































































































    import {Component, Mixins, Watch} from "vue-property-decorator";
    import Dates from "@/components/inputs/Dates.vue";
    import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
    import MasrafCesidiPicker from "@/components/pickers/MasrafCesidiPicker.vue";
    import MasrafTuruPicker from "@/components/pickers/MasrafTuruPicker.vue";
    import ItemArrayManager from "@/components/common-inputs/ItemArrayManager.vue";
    import FormDialogButton from "@/components/common-inputs/FormDialogButton.vue";
    import {VekaletUcretDonemEntity} from "@/entity/VekaletUcretDonemEntity";
    import DeleteButton from "@/components/common-inputs/DeleteButton.vue";
    import VekaletUcretOranForm from "@/components/tercihler/VekaletUcretOranForm.vue";

    @Component({
        components: {
            VekaletUcretOranForm,
            DeleteButton, FormDialogButton, ItemArrayManager, MasrafTuruPicker, MasrafCesidiPicker, Dates
        }
    })
    export default class VekaletDonemList extends Mixins(ObjectInputMixin) {
        selected: Array<VekaletUcretDonemEntity> | null = [];

        @Watch('selected')
        onValueChange() {
            console.log("degisti: ", this.selected);
        }

// selected[0].oranlar - value.ustSinir
        mounted() {
            if (this.localValue.donemler != null) {
                this.selected?.push(this.localValue.donemler[0]);
                console.log("baslangicta: ", this.selected);
            }
        }

        newData() {
            return new VekaletUcretDonemEntity();
        }

        headers = [
            {
                text: "Başlangıç Tarihi",
                value: "baslangicTarihi"
            },
            {
                text: "Bitiş Tarihi",
                value: "bitisTarihi"
            },
            {
                text: "İşlemler",
                value: "actions"
            },
        ];
        OranHeaders = [
            {
                text: "Alt Sınır",
                value: "altSinir"
            },
            {
                text: "Üst Sınır",
                value: "ustSinir"
            },
            {
                text: "Müvekkil Oran",
                value: "muvekkilOran"
            },
            {
                text: "Borçlu Oran",
                value: "borcluOran"
            },
            {
                text: "İşlemler",
                value: "actions"
            },

        ];
    }





































































import {Component, Prop, Vue, Watch} from "vue-property-decorator";

@Component({
    components: {},
})
export default class ViDialog extends Vue {

    @Prop() value: any;
    @Prop() item: any;


    localValue = Object.assign(
        {},
        this.value ? this.value : {},
        this.item ? this.item : {},
    );

    @Watch('value')
    onValueChange() {
        this.localValue = Object.assign({}, this.value ? this.value : {});
    }

    input() {
        console.log("input called, shall emit ", this.localValue);
        this.$emit('input', this.localValue);
        this.$emit('value', this.localValue);
    }

    @Prop() tooltip!: string;
    @Prop() icon!: string;
    @Prop() label!: string;
    @Prop() color!: string;
    @Prop() dark!: string | boolean;
    @Prop() medium!: string | boolean;
    @Prop() isOutlined!: string | boolean;
    @Prop() inline!: string | boolean;
    @Prop() viewOnly!: string | boolean;
    @Prop() action!: string;
    @Prop() initialValue!: object | null | any;
    @Prop() defaultValue!: object | null | any;

    private buttonColor = this.color ? this.color : 'primary'
    // private iconStr = this.icon ? this.icon : 'mdi-message';     icon kullanılmayan butonlarda gereksiz mesaj ikonu çıkarıyordu - kaan
    private iconStr = this.icon ? this.icon : '';

    @Prop({default: "600"}) width!: string | number;

    @Prop() title!: string | null;


    get closeButtonLabel() {
        return (this.isViewOnly ? 'Kapat' : 'Vazgeç');
    }

    get isViewOnly() {
        return this.viewOnly || this.viewOnly === "";
    }

    get isMedium() {
        return this.medium || this.medium === "";
    }

    get isDark() {
        return this.dark || this.dark === "";
    }



    onInput(val: any) {
        console.log("formDialgButton onInput called with", val);
        this.localValue = val;
    }

    dialog: boolean = false;

    onFly: boolean = false;


    initialVal: any;

    get isInline(): boolean {
        return (this.inline && true) || this.inline == '';
    }


    onDialogOpen() {
        this.initialVal = Object.assign({}, this.value);
    }

    cancel() {
        this.dialog = false;
        this.$emit('input', this.initialVal);
        this.localValue = {};
        this.$emit('cancel');
        this.$emit('close');
    }


    log() {
        console.log("this.value", this.value);
    }
}


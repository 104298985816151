









































































































































































































































































































































import {Component, Mixins} from "vue-property-decorator";
import Dates from "@/components/inputs/Dates.vue";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import MasrafCesidiPicker from "@/components/pickers/MasrafCesidiPicker.vue";
import MasrafTuruPicker from "@/components/pickers/MasrafTuruPicker.vue";
import HacizTuruPicker from "@/components/pickers/HacizTuruPicker.vue";
import Tutar from "@/components/inputs/Tutar.vue";
import {HacizTuru} from "@/enum/HacizTuru";
import AdresForm from "@/components/comps/forms/AdresForm.vue";
import SeksenDokuzForm from "@/components/anakart/haciz/SeksenDokuzForm.vue";
import {TebligatDurumu} from "@/enum/TebligatDurumu";
import TebligatTuruPicker from "@/components/pickers/TebligatTuruPicker.vue";
import FormDialogButton from "@/components/common-inputs/FormDialogButton.vue";
import YedieminForm from "@/components/anakart/haciz/YedieminForm.vue";
import IstihkakForm from "@/components/anakart/haciz/IstihkakForm.vue";
import ItemArrayManager from "@/components/common-inputs/ItemArrayManager.vue";
import TasinirForm from "@/components/anakart/haciz/TasinirForm.vue";
import MuzekkereForm from "@/components/anakart/haciz/MuzekkereForm.vue";
import YuzUcDavetiyeVeTebligZarfiForm from "@/components/anakart/haciz/YuzUcDavetiyeVeTebligZarfiForm.vue";
import MaasHacziIsverenForm from "@/components/anakart/haciz/MaasHacziIsverenForm.vue";
import HacizDurumuPicker from "@/components/pickers/HacizDurumuPicker.vue";

@Component({
    components: {
        HacizDurumuPicker,
        MaasHacziIsverenForm,
        YuzUcDavetiyeVeTebligZarfiForm,
        MuzekkereForm,
        TasinirForm,
        ItemArrayManager,
        IstihkakForm,
        YedieminForm,
        FormDialogButton,
        TebligatTuruPicker,
        SeksenDokuzForm, AdresForm, Tutar, HacizTuruPicker, MasrafTuruPicker, MasrafCesidiPicker, Dates
    }
})
export default class HacizForm extends Mixins(ObjectInputMixin) {
    hacizTuru = HacizTuru;
    tebligatDurumu = TebligatDurumu;
    isverenHeaders = [
        {
            text: "İşveren",
            align: "start",
            sortable: false,
            value: "kisiKurumAd"
        },
        {text: "Adres Türü", value: "adres.adresTuru"},
    ];
    malHeaders = [
        {
            text: "Malın Cinsi",
            align: "start",
            sortable: false,
            value: "malinCinsi"
        },
        {text: "Adeti", value: "adet"},
        {text: "Belirlenen Değer", value: "belirlenenDeger"},
    ]
}

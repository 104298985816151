
































































































































































































import {Component, Mixins, Prop, Vue,Watch} from "vue-property-decorator";
import Dates from "@/components/inputs/Dates.vue";
import Tutar from "@/components/inputs/Tutar.vue";
import ParaBirimi from "@/components/inputs/ParaBirimi.vue";
import ItemArrayManager from "@/components/common-inputs/ItemArrayManager.vue";
import {FaizListResponseProvider} from "@/services/FaizService";
import {TeminatTuru} from "@/enum/TeminatTuru";
import {NafakaTuru} from "@/enum/NafakaTuru";
import {IlamliAlacakTuru} from "@/entity/IlamliAlacakTuru";
import {mixins} from "vue-class-component";
import Faizler from "@/components/takip/Belgeler/Faizler.vue";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import {TakipTalebiEntity} from "@/entity/TakipTalebiEntity";
import IlamTuruPicker from "@/components/pickers/IlamTuruPicker.vue";
import AlacaklarForm from "@/components/takip/Belgeler/AlacaklarForm.vue";
import NafakaVeDigerForm from "@/components/takip/Belgeler/NafakaVeDiger.vue";
import {ItemArrayManagerTableHeader, TableHeader} from "@/interface/TableHeader";
import { IlamliAlacakTuruListResponseProvider } from "@/services/AlacakBelgeleri/IlamliAlacakTuruService";


@Component({
    components: {ParaBirimi, Tutar, Dates,IlamTuruPicker,AlacaklarForm,ItemArrayManager,Faizler,NafakaVeDigerForm}
})
export default class IlamliAlacakKalemiForm extends Mixins(ObjectInputMixin) {

    @Prop() takipTalebi!: TakipTalebiEntity;

    faizList = FaizListResponseProvider().data;
    teminatTuru = TeminatTuru;
    nafakaTuru=NafakaTuru;

        alacaklarHeaders: Array<ItemArrayManagerTableHeader> = [
            {
                value: "alacakTuru.tur",
                text: "Alacak Türü",
            },
            {
                value: "borclular.kisiKurum.ad",
                text: "Borçlu",
            },
            {value: "tutar", text: "Tutar", renderer: (row)=> this.$helper.tutarStr(row.tutar) },
            {value: "paraBirimi.ad", text: "Para Birimi"},

        ];


         nafakaVeDigerHeaders: Array<ItemArrayManagerTableHeader> = [
        {
            value: "baslangicTarihi",
            text: "Tarihten",
            renderer: (row) => this.$helper.tarihStr(row.baslangicTarihi)
        },
        {
            value: "bitisTarihi", text: "Tarihe", renderer: (row) => {
                return this.$helper.tarihStr(row.bitisTarihi);
            }
        },
        {value: "tutar", text: "Tutar", renderer: (row)=> this.$helper.tutarStr(row.tutar) },
        {value: "paraBirimi.ad", text: "Para Birimi"},
    ];

}

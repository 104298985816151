



















import {Component, Mixins, Prop, Watch} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import ParaBirimi from "@/components/inputs/ParaBirimi.vue";
import Tutar from "@/components/inputs/Tutar.vue";
import {TahsilHarciEntity} from "@/entity/TahsilHarciEntity";
import {TahsilHarciResponseProvider} from "@/services/TahsilHarciService";
import Dates from "../../inputs/Dates.vue";
import {YedieminEntity} from "@/entity/Haciz/YedieminEntity";
import AdresForm from "@/components/comps/forms/AdresForm.vue";
import MalCinsiPicker from "@/components/pickers/MalCinsiPicker.vue";
import {TasinirTuru} from "@/enum/TasinirTuru";


@Component({
    components: {MalCinsiPicker, AdresForm, Dates, Tutar, ParaBirimi}
})
export default class TasinirForm extends Mixins(ObjectInputMixin) {
    TasinirTuru = TasinirTuru;
}

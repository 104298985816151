












import {Component, Mixins} from "vue-property-decorator";

import EnumPicker from "@/components/common-inputs/EnumPicker.vue";
import {mixins} from "vue-class-component";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";
import {TebligatSekli, TebligatSekliLabel} from "../../enum/TebligatSekli";

@Component({
    components: {EnumPicker}
})
export default class TebligatSekliPicker extends Mixins(SimpleInputMixin) {
    turler = TebligatSekli;
    turlerLabel = TebligatSekliLabel;
}

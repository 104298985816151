













































import {Component, Mixins, Prop, Watch} from "vue-property-decorator";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import ParaBirimi from "@/components/inputs/ParaBirimi.vue";
import Tutar from "@/components/inputs/Tutar.vue";
import Dates from "../../inputs/Dates.vue";
import AdresForm from "@/components/comps/forms/AdresForm.vue";
import {IhaleAlicisi} from "@/enum/IhaleAlicisi";
import IhaleAlicisiPicker from "@/components/pickers/IhaleAlicisiPicker.vue";
import KabulRetPicker from "@/components/pickers/KabulRetPicker.vue";


@Component({
    components: {KabulRetPicker, IhaleAlicisiPicker, AdresForm, Dates, Tutar, ParaBirimi}
})
export default class SatisDetayFormForm extends Mixins(ObjectInputMixin) {
    ihaleAlicisi = IhaleAlicisi;
}





















import {Component, Prop, Vue} from "vue-property-decorator";
import Dates from "@/components/inputs/Dates.vue";
import ItemArrayManager from "@/components/common-inputs/ItemArrayManager.vue";

import {TableHeader} from "@/interface/TableHeader";
import Faizler from "@/components/takip/Belgeler/Faizler.vue";
import IlamliAlacakKalemiForm from "@/components/takip/form/IlamliAlacakKalemiForm.vue";

@Component({
    components: {IlamliAlacakKalemiForm, Faizler, ItemArrayManager, Dates}
})
export default class IlamForm extends Vue {
    @Prop()
    value!: any;
    ilamTuru = this.value.ilamTuru;
    alacaklarHeaders: Array<TableHeader> = [
        {value: "alacakTuru.tur", text: "Alacak Türü"},
        {value: "borclu.ad", text: "Borçlu"},
        {value: "tutar", text: "Tutar"},
        {value: "paraBirimi.ad", text: "Para Birimi"},
        {value: "faizBaslangicTarihi", text: "Faiz Başlangıç Tarihi"},
    ];

    mounted() {
        console.log("ilam formda ne var:", this.value)
    }

}

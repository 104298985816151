var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.listwithtext===false)?_c('v-card',{staticClass:"mx-auto"},[_c('v-toolbar',{attrs:{"dense":"","color":"light-blue darken-4","dark":""}},[_c('v-toolbar-title',[_vm._v(" Borçlu Bilgileri ")]),_c('v-spacer'),_c('form-dialog-button',{attrs:{"dark":"","color":"green","title":"Borçlu Bilgileri","icon":"mdi-plus"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var formData = ref.formData;
var onInput = ref.onInput;
return [_c('borclu-form',{on:{"input":onInput},model:{value:(formData),callback:function ($$v) {formData=$$v},expression:"formData"}})]}}],null,false,657491212)})],1),(_vm.localValue!=null)?_c('v-data-table',{staticClass:"table table-striped",attrs:{"dense":"","headers":_vm.headers,"items":_vm.localValue,"search":_vm.search,"hide-default-footer":"","no-data-text":"Kayıtlı borçlu bulunamadı. Eklemek için + butonunu kullan! "},scopedSlots:_vm._u([{key:"item.kisiKurum.ad",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"seedetails",on:{"click":function($event){return _vm.detay(item)}}},[_vm._v(" "+_vm._s(_vm.$helper.kisiKurumAd(item.kisiKurum))+" ")])]}},{key:"item.kisiKurum.tc",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$helper.tcknOrVergiNo(item.kisiKurum))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"color":"light-blue darken-4","icon":""},on:{"click":function($event){return _vm.detay(item)}}},[_c('v-icon',[_vm._v("mdi-arrow-right-bold-box")])],1),_c('form-dialog-button',{attrs:{"title":"Borçlu Bilgileri","medium":true,"icon":"mdi-pencil"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var formData = ref.formData;
var onInput = ref.onInput;
return [_c('borclu-form',{model:{value:(formData),callback:function ($$v) {formData=$$v},expression:"formData"}})]}}],null,true),model:{value:(_vm.items[ _vm.items.indexOf(item) ]),callback:function ($$v) {_vm.$set(_vm.items,  _vm.items.indexOf(item) , $$v)},expression:"items[ items.indexOf(item) ]"}}),_c('form-dialog-button',{attrs:{"medium":true,"title":"Tebligat Bilgileri","icon":"mdi-alpha-t-box"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var formData = ref.formData;
var onInput = ref.onInput;
return [_c('tebligat-list',{attrs:{"borclu":formData}})]}}],null,true),model:{value:(_vm.items[ _vm.items.indexOf(item) ]),callback:function ($$v) {_vm.$set(_vm.items,  _vm.items.indexOf(item) , $$v)},expression:"items[ items.indexOf(item) ]"}}),(item.tebligatlar.length>0)?_c('form-dialog-button',{attrs:{"medium":true,"title":"Haciz Bilgileri","icon":"mdi-alpha-h-box"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var formData = ref.formData;
var onInput = ref.onInput;
return [_c('haciz-list',{attrs:{"items":formData.hacizler}})]}}],null,true),model:{value:(_vm.items[ _vm.items.indexOf(item) ]),callback:function ($$v) {_vm.$set(_vm.items,  _vm.items.indexOf(item) , $$v)},expression:"items[ items.indexOf(item) ]"}}):_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',[_vm._v(" mdi-alpha-h-box ")])],1),_c('delete-button')]}},{key:"item.state",fn:function(ref){
var item = ref.item;
return [(item.hacizler.length>0)?_c('span',{staticClass:"blink",staticStyle:{"color":"forestgreen"}},[_vm._v("Haciz")]):(item.tebligatlar.length>0)?_c('span',{staticClass:"blink",staticStyle:{"color":"orange"}},[_vm._v("Tebligat")]):_c('span',{staticClass:"blink",staticStyle:{"color":"red"}},[_vm._v("Başlamadı")])]}}],null,false,2164618450)}):_vm._e()],1):_c('v-card',[_vm._l((_vm.localValue),function(item,index){return [_c('form-dialog-button',{attrs:{"title":"Borçlu Bilgileri"},on:{"success":function (item) { return (_vm.localValue[index] = item); }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var localValue = ref.localValue;
return [_c('borclu-form',{attrs:{"item":localValue},model:{value:(localValue),callback:function ($$v) {localValue=$$v},expression:"localValue"}})]}},{key:"activator",fn:function(ref){
var on = ref.on;
var localValue = ref.localValue;
return [_c('span',[(index != 0)?_c('span',[_vm._v(", ")]):_vm._e(),_c('a',_vm._g({},on),[_vm._v(" "+_vm._s(item.kisiKurum.ad)+" ")])])]}}],null,true)})]})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
import {TahsilHarciEntity} from "@/entity/TahsilHarciEntity";

export interface TahsilHarciResponse {
    data: TahsilHarciEntity[]
}

export function TahsilHarciResponseProvider(): TahsilHarciResponse {
    return {
        data: [
            {
                id: 1,
                oran: 2.27
            },
            {
                id: 2,
                oran: 4.55
            },
            {
                id: 3,
                oran: 9.10
            },
            {
                id: 4,
                oran: 11.38
            },

        ]
    }
}
import {TaksitlendirmeEntity} from "@/entity/TaksitlendirmeEntity";

class TaksitlendirmeListResponse {
    data!: TaksitlendirmeEntity[]
}

export function TaksitlendirmeListResponseProvider(depth:number=3): TaksitlendirmeListResponse {
    return {
        data: [
            {
                siraNo : 1,
                tutar : 1000,
                tarih : new Date("2017-01-02"),
                kalanAnaPara:9000
            },
            {
                siraNo : 2,
                tutar : 1000,
                tarih : new Date("2017-02-02"),
                kalanAnaPara:8000
            },
            {
                siraNo : 3,
                tutar : 1000,
                tarih : new Date("2017-03-02"),
                kalanAnaPara:7000
            },
            {
                siraNo : 4,
                tutar : 1000,
                tarih : new Date("2017-04-02"),
                kalanAnaPara:6000
            },
            {
                siraNo : 5,
                tutar : 1000,
                tarih : new Date("2017-05-02"),
                kalanAnaPara:5000
            },
        ]
    }
}

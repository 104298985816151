



















































































import {Component, Mixins, Prop, Vue} from "vue-property-decorator";
import ProtestoIhtar from "@/components/takip/Belgeler/ProtestoIhtar.vue";
import {PoliceEntity} from "@/entity/AlacakBelgeler/PoliceEntity";
import Dates from "@/components/inputs/Dates.vue";
import ParaBirimi from "@/components/inputs/ParaBirimi.vue";
import IlPicker from "@/components/pickers/IlPicker.vue";
import {mixins} from "vue-class-component";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import Faizler from "@/components/takip/Belgeler/Faizler.vue";
import Tutar from "@/components/inputs/Tutar.vue";

@Component({
    components: {Tutar, Faizler, IlPicker, ParaBirimi, Dates, ProtestoIhtar}
})
export default class Police extends Mixins(ObjectInputMixin) {

    autoUpdate:boolean=false;

}














import {Component, Mixins} from "vue-property-decorator";
import EnumPicker from "@/components/common-inputs/EnumPicker.vue";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";

import {TasinirTuru, TasinirTuruLabel} from "../../enum/TasinirTuru";

@Component({
    components: {EnumPicker}
})
export default class MalCinsiPicker extends Mixins(SimpleInputMixin) {
    turler = TasinirTuru;
    turlerLabel = TasinirTuruLabel;
}

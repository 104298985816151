var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mx-auto"},[_c('v-toolbar',{attrs:{"color":"light-blue darken-4","dark":"","dense":""}},[_c('v-toolbar-title',[_vm._v("Vekaletler")]),_c('v-spacer'),_c('form-dialog-button',{attrs:{"title":"Vekalet Bilgileri","icon":"mdi-plus","dark":""},on:{"success":function (o) { this$1.items.push(o) }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var formData = ref.formData;
var onInput = ref.onInput;
return [_c('vekalet-form',{on:{"input":onInput},model:{value:(formData),callback:function ($$v) {formData=$$v},expression:"formData"}})]}}])})],1),(_vm.items!=null)?_c('v-data-table',{staticClass:"table table-striped",attrs:{"headers":_vm.headers,"items":_vm.items,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.detay(item)}}},[_c('v-icon',[_vm._v("mdi-file")])],1),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.deleteBuro(item)}}},[_c('v-icon',[_vm._v("mdi-delete-outline")])],1)]}}],null,false,3648686204)}):_c('v-skeleton-loader',{attrs:{"type":"table"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }





























import {Component, Mixins, Prop, Vue, Watch} from "vue-property-decorator";
import {AdresEntity} from "@/entity/AdresEntity";
import {AdresTuruLabel} from "@/enum/AdresTuru";
import FormDialogButton from "@/components/common-inputs/FormDialogButton.vue";
import AdresForm from "@/components/comps/forms/AdresForm.vue";

@Component({
    components: {AdresForm, FormDialogButton}
})
export default class AdresSelect extends Vue {

    AdresTuruLabel = AdresTuruLabel;

    @Prop() items!: AdresEntity[];
    selecteds: AdresEntity[] = [];
    @Prop() value!: AdresEntity;
    @Prop() xmlvar!: boolean;

    @Watch('selecteds')
    onValueChange() {
      //  this.value = this.selecteds[0];
        console.log("emit etmesi lazım");
        this.$emit("close-select");
    }

    yeniAdres = new AdresEntity();
    headers = [
        {
            text: "Adres Türü",
            align: "start",
            sortable: true,
            value: "adresTuru"
        },
        {text: "İl", value: "ilce.il.isim"},
        {text: "İlçe", value: "ilce.isim"},
        {text: "Kaynak", value: "kaynak"},
    ];
}


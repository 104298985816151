













import {Component, Mixins, Prop, Vue} from "vue-property-decorator";
import {FaizListResponseProvider} from "@/services/FaizService";
import {FaizHesapSekilListResponseProvider} from "@/services/FaizHesapSekilleriService";
import {mixins} from "vue-class-component";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import BankaBilgileriForm from "@/components/comps/forms/BankaBilgileriForm.vue";

@Component({
    components: {BankaBilgileriForm}
})
export default class BankaBilgileri extends Mixins(ObjectInputMixin) {


    mounted(){
            console.log("formDATA",this.localValue);
    }
}















import {Component, Mixins} from "vue-property-decorator";

import EnumPicker from "@/components/common-inputs/EnumPicker.vue";
import {mixins} from "vue-class-component";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";
import {TebligatDurumu, TebligatDurumuLabel} from "../../enum/TebligatDurumu";
import {GonderilenTuru, GonderilenTuruLabel} from "../../enum/GonderilenTuru";

@Component({
    components: {EnumPicker}
})
export default class GonderilenPicker extends Mixins(SimpleInputMixin) {
    turler = GonderilenTuru;
    turlerLabel = GonderilenTuruLabel;
}

















































import {Component, Prop, Vue} from "vue-property-decorator";
import MasrafForm from "@/components/anakart/masraf/MasrafForm.vue";
import FormDialogButton from "@/components/common-inputs/FormDialogButton.vue";
import DeleteButton from "@/components/common-inputs/DeleteButton.vue";
import {MasrafEntity} from "@/entity/MasrafEntity";
import {MasrafCesidiLabel} from "@/enum/MasrafCesidi";
import {MasrafTuru} from "@/enum/MasrafTuru";


@Component({
    components: {DeleteButton, FormDialogButton, MasrafForm}
})
export default class MasrafList extends Vue {
    @Prop({})
    items!: Array<MasrafEntity>;
    value!: Array<MasrafEntity>;

    localValue: Array<MasrafEntity> = Array.isArray(this.value) ? this.value : Array.isArray(this.items) ? this.items : [];
    masrafCesidiLabel = MasrafCesidiLabel;
    masrafTuru=MasrafTuru;
    search: string = "";
    headers = [
        {
            text: "Tarih",
            align: "start",
            sortable: false,
            value: "masrafTarihi"
        },
        {text: "Tutar", value: "masrafTutari", align: 'right'},
        {
            text: "Masraf Çeşidi",
            align: "start",
            sortable: false,
            value: "masrafCesidi"
        },
        {text: "Resmi / Gayriresmi", value: "resmiMi"},
        {text: "Masrafı Yapan", value: "masrafYapanKisi"},
        {text: "İşlemler", value: "actions", sortable: false}

    ];
}

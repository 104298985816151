



























import {Component, Mixins} from "vue-property-decorator";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import ParaBirimi from "@/components/inputs/ParaBirimi.vue";
import Tutar from "@/components/inputs/Tutar.vue";
import Dates from "../../inputs/Dates.vue";
import AdresForm from "@/components/comps/forms/AdresForm.vue";


@Component({
    components: { AdresForm, Dates, Tutar, ParaBirimi}
})
export default class MuzekkereForm extends Mixins(ObjectInputMixin) {
    Adet:number=1;
    zarfdaOlusturulsun:boolean=false;
    mounted(){
        console.log("kayip:",this.localValue);
    }
}














import {Component, Mixins, Prop, Vue, Watch} from "vue-property-decorator";
import EnumPicker from "@/components/common-inputs/EnumPicker.vue";
import {mixins} from "vue-class-component";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";
import {IlamsizBelgeTipi, IlamsizBelgeTipiLabel} from "../../enum/IlamsizBelgeTipi";

@Component({
    components: {EnumPicker}
})
export default class BelgeTuruPicker extends Mixins(SimpleInputMixin) {
    turler = IlamsizBelgeTipi;
    turlerLabel = IlamsizBelgeTipiLabel;
}

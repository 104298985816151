












import {Component, Mixins, Prop, Vue, Watch} from "vue-property-decorator";
import EnumPicker from "@/components/common-inputs/EnumPicker.vue";
import {mixins} from "vue-class-component";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";
import {IlamTipi, IlamTipiLabel} from "../../enum/IlamTipi";
import {IhaleAlicisi, IhaleAlicisiLabel} from "../../enum/IhaleAlicisi";
import {KabulRet, KabulRetLabel} from "../../enum/KabulRet";

@Component({
    components: {EnumPicker}
})
export default class IhaleAlicisiPicker extends Mixins(SimpleInputMixin) {
    kabulretlabel = KabulRetLabel;
    kabulret = KabulRet;
}












































import {Component, Mixins, Prop, Vue, Watch} from "vue-property-decorator";
import {AlacakKalemleriEntity} from "@/entity/AlacakBelgeler/AlacakKalemleriEntity";
import AlacakKalemleriForm from "@/components/takip/form/AlacakKalemleriForm.vue";
import {mixins} from "vue-class-component";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import FormDialogButton from "@/components/common-inputs/FormDialogButton.vue";
import {IlamsizBelgeTipiLabel} from "@/enum/IlamsizBelgeTipi";


@Component({
    components: {FormDialogButton, AlacakKalemleriForm}
})
export default class AlacakKalemleriList extends Vue {

    @Prop({})
    listwithtext!: boolean;

    @Prop({})
    items!: Array<AlacakKalemleriEntity>;
    value!: Array<AlacakKalemleriEntity>;

    localValue: Array<AlacakKalemleriEntity> = Array.isArray(this.value) ? this.value : Array.isArray(this.items) ? this.items : [];

    IlamsizBelgeTipiLabel=IlamsizBelgeTipiLabel;

    headers = [
        {
            text: "Belge Tipi",
            align: "start",
            sortable: false,
            value: "belgeTipi"
        },
        {
            text: "Tutar",
            align: "start",
            sortable: false,
            value: "tutar"
        },
        {text: "Vade Tarihi", value: "belgeTarihi"},
        {text: "İşlemler", value: "actions", sortable: false}

    ];

    @Watch('value')
    onValueChange() {
        this.localValue = Array.isArray(this.value) ? this.value : [];
    }

    input() {
        this.$emit('input', this.localValue);
    }

    mounted() {
        console.log("ne geldi", this.value);
    }
    detay(item:AlacakKalemleriEntity) {

    }
}


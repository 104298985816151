


























import {Component, Mixins} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import KisiKurumForm from "@/components/kisi/kisiKurumForm.vue";
import Tutar from "@/components/inputs/Tutar.vue";
import {TarafSifatiListResponseProvider} from "@/services/TarafSifatiService";


@Component({
    components: {Tutar, KisiKurumForm}
})
export default class borcluForm extends Mixins(ObjectInputMixin) {

    TarafSifatlari = TarafSifatiListResponseProvider().data;

    mounted() {
        console.log("borclu form verisi: ", this.localValue);
    }
}
/*
*             <v-col md="4">
            <v-checkbox label="Borçlu Kefili" v-model="localValue.kefilVarMi" @input="input"></v-checkbox>
        </v-col> */

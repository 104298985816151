























































































































import {Component, Mixins, Prop} from "vue-property-decorator";
import {TakipTalebiEntity} from "@/entity/TakipTalebiEntity";
import TakipTalebiForm from "@/components/takip/form/TakipTalebiForm.vue";
import FormDialogButton from "@/components/common-inputs/FormDialogButton.vue";
import Dates from "@/components/inputs/Dates.vue";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import DosyaEsasNumarasi from "@/components/inputs/DosyaEsasNumarasi.vue";
import {mask} from "vue-the-mask";
@Component({
    directives:{
        mask
    },
    components: {DosyaEsasNumarasi, FormDialogButton, TakipTalebiForm, Dates}
})
export default class TakipTalebiList extends Mixins(ObjectInputMixin) {
    @Prop({})
    item!: TakipTalebiEntity;
    dialog = false;
    details = true;

    maskStr= "####/#######";

    validate() {
        this.$emit("value", this.item.dosyaEsasNo);
    }
    mounted() {
        console.log("seboooo", this.item);
    }
    SeeHideDetails() {
        this.details = !this.details;
    }
    close() {
        this.dialog = false;
    }
}

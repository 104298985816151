






























import {Component, Mixins} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import ParaBirimi from "@/components/inputs/ParaBirimi.vue";
import Tutar from "@/components/inputs/Tutar.vue";
import Dates from "../../inputs/Dates.vue";
import AdresForm from "@/components/comps/forms/AdresForm.vue";


@Component({
    components: {AdresForm, Dates, Tutar, ParaBirimi}
})
export default class IstihkakForm extends Mixins(ObjectInputMixin) {

}




















































import {Component, Prop, Vue} from "vue-property-decorator";

import FormDialogButton from "@/components/common-inputs/FormDialogButton.vue";
import DeleteButton from "@/components/common-inputs/DeleteButton.vue";
import {HacizEntity} from "../../../entity/Haciz/HacizTemelEntity";
import {HacizTuru, HacizTuruIcon, HacizTuruLabel} from "@/enum/HacizTuru";
import HacizForm from "@/components/anakart/haciz/HacizForm.vue";
import SatisForm from "@/components/anakart/haciz/SatisForm.vue";


@Component({
    components: {SatisForm, HacizForm, DeleteButton, FormDialogButton}
})
export default class HacizList extends Vue {
    @Prop({})
    items!: Array<HacizEntity>;
    value!: Array<HacizEntity>;

    localValue: Array<HacizEntity> = Array.isArray(this.value) ? this.value : Array.isArray(this.items) ? this.items : [];
    hacizTuruLabel = HacizTuruLabel;
    hacizTuruIcon = HacizTuruIcon;
    hacizTuru = HacizTuru;
    search: string = "";
    headers = [
        {text: "Sırası", value: "hacizSirasi",sortable: false},
        {text: "", value: "hacizIcon",sortable: false},
        {
            text: "Türü",
            align: "start",
            value: "hacizTuru"
        },
        {
            text: "Haciz Tarihi",
            align: "start",
            value: "hacizTarihi"
        },
        {text: "Yenileme", value: "yenilemeMi", sortable: false},
        {text: "Gerçekleşti", value: "gerceklestiMi", sortable: false},
        {text: "İşlemler", value: "actions", sortable: false}

    ];


}

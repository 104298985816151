


























































import {Component, Mixins, Prop, Vue} from "vue-property-decorator";
import ProtestoIhtar from "@/components/takip/Belgeler/ProtestoIhtar.vue";
import {CariHesapAlacakEntity} from "@/entity/AlacakBelgeler/CariHesapAlacakEntity";
import Dates from "@/components/inputs/Dates.vue";
import {PoliceEntity} from "@/entity/AlacakBelgeler/PoliceEntity";
import ParaBirimi from "@/components/inputs/ParaBirimi.vue";
import Faizler from "@/components/takip/Belgeler/Faizler.vue";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import {mixins} from "vue-class-component";
import Tutar from "@/components/inputs/Tutar.vue";

@Component({
    components: {Tutar, Faizler, ParaBirimi, Dates, ProtestoIhtar}
})
export default class CariHesapAlacak extends Mixins(ObjectInputMixin) {

    autoUpdate:boolean=false;
}

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mx-auto align-self-stretch"},[_c('v-toolbar',{attrs:{"dense":"","color":"light-blue darken-4","dark":""}},[_c('v-toolbar-title',[_vm._v("Tebligatlar")]),_c('v-spacer'),_c('form-dialog-button',{attrs:{"width":600,"title":"Tebligat Bilgileri","dark":"","icon":"mdi-plus"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var formData = ref.formData;
var onInput = ref.onInput;
return [_c('tebligat-form',{attrs:{"borclu":_vm.borclu},on:{"input":onInput},model:{value:(formData),callback:function ($$v) {formData=$$v},expression:"formData"}})]}}])})],1),(_vm.localValue!=null)?_c('v-data-table',{staticClass:"table table-striped",attrs:{"dense":"","headers":_vm.headers,"items":_vm.localValue,"search":_vm.search,"hide-default-footer":"","no-data-text":"Kayıtlı alacak kalemi bulunamadı. Eklemek için + butonunu kullan! "},scopedSlots:_vm._u([{key:"item.tebligatGonderimTarihi",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$helper.tarihStr(item.tebligatGonderimTarihi))+" ")]}},{key:"item.tebligatDurumu",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.TebligatDurumu[item.tebligatDurumu])+" ")]}},{key:"item.tebligatSekli",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.TebligatSekli[item.tebligatSekli])+" ")]}},{key:"item.itirazVarMi",fn:function(ref){
var item = ref.item;
return [(item.itirazVarMi === true)?_c('v-icon',[_vm._v("mdi-check-box-outline")]):_c('v-icon',[_vm._v("mdi-crop-square")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('form-dialog-button',{attrs:{"width":600,"title":"Tebligat Bilgileri","medium":"","icon":"mdi-pencil"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var formData = ref.formData;
var onInput = ref.onInput;
return [_c('tebligat-form',{attrs:{"borclu":_vm.borclu},on:{"input":onInput},model:{value:(formData),callback:function ($$v) {formData=$$v},expression:"formData"}})]}}],null,true),model:{value:(_vm.borclu.tebligatlar[ _vm.borclu.tebligatlar.indexOf(item) ]),callback:function ($$v) {_vm.$set(_vm.borclu.tebligatlar,  _vm.borclu.tebligatlar.indexOf(item) , $$v)},expression:"borclu.tebligatlar[ borclu.tebligatlar.indexOf(item) ]"}}),_c('delete-button')]}}],null,false,1495616492)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }
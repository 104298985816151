


































import {Component, Mixins, Prop, Watch} from "vue-property-decorator";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import ParaBirimi from "@/components/inputs/ParaBirimi.vue";
import Tutar from "@/components/inputs/Tutar.vue";
import {TahsilHarciEntity} from "@/entity/TahsilHarciEntity";
import {TahsilHarciResponseProvider} from "@/services/TahsilHarciService";


@Component({
    components: {Tutar, ParaBirimi}
})
export default class HarcForm extends Mixins(ObjectInputMixin) {
    @Prop()
    takipToplami!: number;
    pesinHarcTutari: number | null = null;
    pesinHarcTutariFixed: string | null = null;
    tahsilHarcTutari: number | null = null;
    tahsilHarcTutariFixed: string | null = null;
    tahsilHarclari: Array<TahsilHarciEntity> = TahsilHarciResponseProvider().data;
    toplam: number | null = null;
    toplamFixed: string | null = null;

    @Watch('localValue')
    onLocalValueChanged() {
        this.pesinHarcTutari = this.takipToplami * this.localValue.pesinHarcOrani / 100;
        this.pesinHarcTutariFixed = this.pesinHarcTutari.toFixed(2);
        this.tahsilHarcTutari = this.takipToplami * this.localValue.tahsilHarciOrani / 100;
        this.tahsilHarcTutariFixed = this.tahsilHarcTutari.toFixed(2);
        this.toplam = this.tahsilHarcTutari + this.pesinHarcTutari + this.localValue.vekaletHarci + this.localValue.basvuruHarci + this.localValue.idareHarci;
        if (this.toplam != null)
            this.toplamFixed = this.toplam.toFixed(2);
    }
}

import {enum2select, SelectableListResponse} from "@/helpers/EnumHelper";
import {MasrafTuruLabel} from "@/enum/MasrafTuru";



export function MasrafTuruListResponseProvider(depth:number=3): SelectableListResponse {
    return {
        data: enum2select(MasrafTuruLabel)
    };
}















































































import {Component, Mixins, Prop, Vue, Watch} from "vue-property-decorator";
import KisiKurumForm from "@/components/kisi/kisiKurumForm.vue";
import {BorcluEntity} from "@/entity/BorcluEntity";
import FormDialogButton from "@/components/common-inputs/FormDialogButton.vue";
import BorcluForm from "@/components/takip/form/BorcluForm.vue";
import DeleteButton from "@/components/common-inputs/DeleteButton.vue";
import TebligatForm from "@/components/anakart/tebligat/TebligatForm.vue";
import TebligatList from "@/components/anakart/tebligat/TebligatList.vue";
import HacizList from "@/components/anakart/haciz/HacizList.vue";

@Component({
    components: {HacizList, TebligatList, TebligatForm, DeleteButton, BorcluForm, FormDialogButton, KisiKurumForm}
})
export default class BorclularList extends Vue {
    @Prop({})
    value!: Array<BorcluEntity>;

    @Prop({})
    items!: Array<BorcluEntity>;

    localValue: Array<BorcluEntity> = Array.isArray(this.value) ? this.value : Array.isArray(this.items) ? this.items : [];

    @Watch('value')
    onValueChange() {
        this.localValue = Array.isArray(this.value) ? this.value : [];
    }

    add(newItem: any) {
        this.localValue.push(newItem);
        this.input();
    }

    @Prop({})
    listwithtext!: boolean;

    dialog: boolean = false;
    dialogKK: boolean = false;

    search: string = "";
    editingObject !: BorcluEntity;
    headers = [
        {
            text: "Adı Soyadı/Unvanı",
            align: "start",
            sortable: false,
            value: "kisiKurum.ad"
        },
        {text: "TC Kimlik No / Vergi Kimlik No", value: "kisiKurum.tc"},
        {text: "İşlemler", value: "actions", sortable: false},
        {text: "Durum", value: "state", sortable: false},


    ];

    detay(item: BorcluEntity) {
        this.$router.push('/borclu/' + item.id?.toString() + '/detay');
    }

    input() {
        this.$emit('input', this.localValue);
    }
}

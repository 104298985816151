









































import {Component, Vue} from "vue-property-decorator";

@Component({
    components: {

    }
})

export default class HesapTarihi extends Vue{
    date = new Date().toISOString().substr(0, 10);
    menu = false;
    modal = false;
}





































import {Component, Prop, Vue} from "vue-property-decorator";
import PersonelBilgiForm from "@/components/buro/personel/PersonelBilgiForm.vue";
import FormDialogButton from "@/components/common-inputs/FormDialogButton.vue";
import AvukatSec from "@/components/anakart/AvukatSec.vue";
import {AvukatPersonelEntity} from "@/entity/AvukatPersonelEntity";

@Component({
    components: {AvukatSec, FormDialogButton, PersonelBilgiForm}
})
export default class YetkiliAvukat extends Vue {
    @Prop()
    avukat!: AvukatPersonelEntity;
    AvukatSucces(item: AvukatPersonelEntity){
        this.avukat = item;
    }

}














import {Component, Mixins} from "vue-property-decorator";
import EnumPicker from "@/components/common-inputs/EnumPicker.vue";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";
import {HacizDurumu, HacizDurumuLabel} from "../../enum/HacizDurumu";

@Component({
    components: {EnumPicker}
})
export default class HacizDurumuPicker extends Mixins(SimpleInputMixin) {

    turler = HacizDurumu;
    turlerLabel = HacizDurumuLabel;
}














import {Component, Mixins, Prop, Vue, Watch} from "vue-property-decorator";
import {AdresTuru, AdresTuruLabel} from "@/enum/AdresTuru";
import EnumPicker from "@/components/common-inputs/EnumPicker.vue";
import {mixins} from "vue-class-component";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";
import {MasrafCesidi, MasrafCesidiLabel} from "../../enum/MasrafCesidi";

@Component({
    components: {EnumPicker}
})
export default class MasrafCesidiPicker extends Mixins(SimpleInputMixin) {
    turler = MasrafCesidi;
    turlerLabel = MasrafCesidiLabel;
}



































































































import {Component, Prop, Vue} from "vue-property-decorator";
import TahsilatForm from "@/components/anakart/tahsilat/TahsilatForm.vue";
import DeleteButton from "@/components/common-inputs/DeleteButton.vue";
import FormDialogButton from "@/components/common-inputs/FormDialogButton.vue";
import {TahsilatEntity} from "@/entity/TahsilatEntity";

@Component({
    components: {FormDialogButton, DeleteButton, TahsilatForm}
})
export default class TahsilatList extends Vue {
    mounted() {
        console.log("tahsilat list gercek: ", this.items);
        this.Hesapla();
    }

    Hesapla() {
        this.items.forEach((tahsilat: TahsilatEntity) => {
            if (tahsilat.tarih != null && tahsilat.tutar != null) {
                if (tahsilat.tarih < this.takipTarihi) {
                    this.takipOncesiToplam += tahsilat.tutar;
                } else {
                    this.takipSonrasiToplam += tahsilat.tutar;
                }
            }
        });
    }

    @Prop({})
    items!: Array<TahsilatEntity>;
    value!: Array<TahsilatEntity>;
    localValue: Array<TahsilatEntity> = Array.isArray(this.value) ? this.value : Array.isArray(this.items) ? this.items : [];
    takipTarihi = new Date("2008-03-14");
    taahhütTaksitlendirme = 0;
    takipOncesiToplam = 0;
    takipSonrasiToplam = 0;
    search: string = "";
    headers = [
        {
            text: "Borçlu Adı Soyadı/Unvanı",
            align: "start",
            sortable: false,
            value: "borclu.kisiKurum.ad",
            renderer: (row: TahsilatEntity) => this.$helper.kisiKurumAd(row.borclu?.kisiKurum)
        },
        {
            text: "Tahsilatı Yapan",
            align: "start",
            sortable: false,
            value: "tahsilatiYapanKisi"
        },
        {text: "Tutar", value: "tutar", align: 'right'},
        {text: "Tarih", value: "tarih"},
        {text: "İşlemler", value: "actions", sortable: false}
    ];
}

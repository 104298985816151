












import {Component, Mixins} from "vue-property-decorator";
import EnumPicker from "@/components/common-inputs/EnumPicker.vue";
import {mixins} from "vue-class-component";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";
import {HacizTuru, HacizTuruLabel} from "../../enum/HacizTuru";

@Component({
    components: {EnumPicker}
})
export default class HacizTuruPicker extends Mixins(SimpleInputMixin) {

    turler = HacizTuru;
    turlerLabel = HacizTuruLabel;
}

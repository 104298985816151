






































import {Component, Prop, Vue} from "vue-property-decorator";
import {VekaletListResponseProvider} from "@/services/VekaletService";
import {VekaletEntity} from "../../entity/VekaletEntity";
import {TableHeader} from "@/interface/TableHeader";
import MuvekkilList from "@/components/vekalet/MuvekkilList.vue";
import FormDialogButton from "@/components/common-inputs/FormDialogButton.vue";
import NoterIslemForm from "@/components/comps/forms/NoterIslemForm.vue";
import VekaletForm from "@/components/vekalet/VekaletForm.vue";
import {NoterEntity} from "@/entity/NoterEntity";

@Component({
    components: {VekaletForm, NoterIslemForm, FormDialogButton, MuvekkilList}
})
export default class YeniTakipVekaletListesi extends Vue {


    @Prop({})
    items!: VekaletEntity[];

    headers: TableHeader[] = [
        {
            text: "Vekalet No",
            align: "start",
            sortable: false,
            value: "id"
        },
        {
            text: "Vekalet Adı",
            align: "start",
            sortable: false,
            value: "ad"
        }
    ];

    vekalet = {};

    mounted() {
        window.setTimeout(() => {
            console.log("vekaletler:",this.items);
        }, 500);

    }

    add() {
        this.$router.push("/vekalet/ekle");
    }

    detay(item: VekaletEntity) {
        this.$router.push("/vekalet/" + item.id?.toString() + "/detay");
    }
}

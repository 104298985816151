










































































import {Component, Mixins, Prop, Vue} from "vue-property-decorator";
import Dates from "@/components/inputs/Dates.vue";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import TahsilatTuruPicker from "@/components/pickers/TahsilatTuruPicker.vue";
import ParaBirimi from "@/components/inputs/ParaBirimi.vue";
import Tutar from "@/components/inputs/Tutar.vue";
import TaksitSayisinaGoreTaksitlendirme
    from "@/components/anakart/taksitlendirme/taksitlendirme-tipleri/TaksitSayisinaGoreTaksitlendirme.vue";
import TaksitTutarinaGoreTaksitlendirme
    from "@/components/anakart/taksitlendirme/taksitlendirme-tipleri/TaksitTutarinaGoreTaksitlendirme.vue";
import KisisellestirilmisTaksitlendirme
    from "@/components/anakart/taksitlendirme/taksitlendirme-tipleri/KisisellestirilmisTaksitlendirme.vue";
import TaksitAnaBilgiler from "@/components/anakart/taksitlendirme/TaksitAnaBilgiler.vue";
import {PersonelListResponseProvider} from "@/services/PersonelService";
import {PersonelTuru} from "@/enum/PersonelTuru";
import {TaksitlendirmeListResponseProvider} from "@/services/TaksitlendirmeService";


@Component({
    components: {
        TaksitAnaBilgiler,
        KisisellestirilmisTaksitlendirme,
        TaksitTutarinaGoreTaksitlendirme,
        TaksitSayisinaGoreTaksitlendirme, Tutar, ParaBirimi, TahsilatTuruPicker, Dates}
})
export default class TaksitlendirmeForm extends Mixins(ObjectInputMixin) {

    taksitler = TaksitlendirmeListResponseProvider().data;


    headers = [
        {
            text: "Sıra No",
            align: "start",
            sortable: true,
            value: "siraNo"
        },
        {text: "Tarih", value: "tarih"},
        {text: "Taksit Tutarı", value: "tutar"},
        {text: "Kalan Ana Para", value: "kalanAnaPara"}
    ]

}



























































import {Component, Mixins, Prop, Vue} from "vue-property-decorator";
import Dates from "@/components/inputs/Dates.vue";
import {MasrafCesidiListResponseProvider} from "@/services/MasrafCesidiService";
import {MasrafTuruListResponseProvider} from "@/services/MasrafTuruService";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import MasrafCesidiPicker from "@/components/pickers/MasrafCesidiPicker.vue";
import MasrafTuruPicker from "@/components/pickers/MasrafTuruPicker.vue";

@Component({
    components: {MasrafTuruPicker, MasrafCesidiPicker, Dates}
})
export default class MasrafForm extends Mixins(ObjectInputMixin) {
    masrafTurleri = MasrafTuruListResponseProvider().data;
    masrafCesitleri = MasrafCesidiListResponseProvider().data;
}

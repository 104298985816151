






























































import {Component, Mixins, Prop, Vue} from "vue-property-decorator";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import {TakipAltTipAyarlarResponseProvider} from "@/services/TakipAltTipAyarlarService";
import HesapDonemiPicker from "@/components/pickers/HesapDonemiPicker.vue";
import HesapSekliPicker from "@/components/pickers/HesapSekliPicker.vue";
import FormDialogButton from "@/components/common-inputs/FormDialogButton.vue";
import draggable from "vuedraggable";
@Component({
    components: {HesapDonemiPicker, HesapSekliPicker, FormDialogButton,draggable}
})
export default class AlacakKalemleriSecenekForm extends Mixins(ObjectInputMixin){
    items = TakipAltTipAyarlarResponseProvider().data;
}

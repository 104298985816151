










import {Component, Mixins} from "vue-property-decorator";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import AdresForm from "../../comps/forms/AdresForm.vue";



@Component({
    components: {AdresForm }
})
export default class MaasHacziIsverenForm extends Mixins(ObjectInputMixin) {
}




















































import {Component, Mixins, Prop, Vue, Watch} from "vue-property-decorator";
import Cek from "@/components/takip/Belgeler/Cek.vue";
import Senet from "@/components/takip/Belgeler/Senet.vue";
import Police from "@/components/takip/Belgeler/Police.vue";
import Fatura from "@/components/takip/Belgeler/Fatura.vue";
import KiraKontrati from "@/components/takip/Belgeler/KiraKontrati.vue";
import Sozlesme from "@/components/takip/Belgeler/Sozlesme.vue";
import Diger from "@/components/takip/Belgeler/Diger.vue";
import CezaiSart from "@/components/takip/Belgeler/CezaiSart.vue";
import TuketiciHakemHeyetiKarari from "@/components/takip/Belgeler/TuketiciHakemHeyetiKarari.vue";
import Aidat from "@/components/takip/Belgeler/Aidat.vue";
import CariHesapAlacak from "@/components/takip/Belgeler/CariHesapAlacak.vue";
import {IlamsizBelgeTipi} from "@/enum/IlamsizBelgeTipi";
import IptalButton from "@/components/inputs/IptalButton.vue";
import KaydetButton from "@/components/inputs/KaydetButton.vue";
import IlamForm from "@/components/takip/Belgeler/IlamForm.vue";
import {IlamTuru} from "@/enum/IlamTuru";
import {mixins} from "vue-class-component";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import BelgeTuruPicker from "@/components/pickers/BelgeTuruPicker.vue";


@Component({
    components: {
        BelgeTuruPicker,
        IlamForm,
        KaydetButton,
        IptalButton,
        CariHesapAlacak,
        Aidat, TuketiciHakemHeyetiKarari, CezaiSart, Sozlesme, KiraKontrati, Fatura, Police, Senet, Cek,Diger
    }
})
export default class AlacakKalemleriForm extends Mixins(ObjectInputMixin) {

    IlamsizBelgeTipi = IlamsizBelgeTipi;
    IlamTuru = IlamTuru;

    mounted() {
        console.log("belge tipi:", this.localValue.belgeTipi)
        console.log("belge gelen:", this.localValue)
    }

    close() {
        console.log("close event emitted");
        this.$emit("close");
    }

    save() {
        this.$emit('input', this.localValue);
        this.$emit('success', this.localValue);
        this.$emit('close');

    }
}

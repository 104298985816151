export enum TasinirTuru
{
    bilgisayar="bilgisayar",
    dizustubilgisayar="dizustubilgisayar",
    ceptelefonu="tablet",
    tablet="tablet",
    para="para",
    hamilineaitsenet="hamilineaitsenet",
    cirosukabilsenet="cirosukabilsenet",
    altin="altin",
    gumus="gumus",
    diger="diger"


}
export enum TasinirTuruLabel
{
    bilgisayar="Bilgisayar",
    dizustubilgisayar="Dizüstü Bilgisayar",
    ceptelefonu="Cep Telefonu",
    tablet="Tablet",
    para="Para",
    hamilineaitsenet="Hamiline Ait Senet",
    cirosukabilsenet="Cirosu Kabil Senet",
    altin="Altın",
    gumus="Gümüş",
    diger="Diğer"

}
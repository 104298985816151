












import {Component, Mixins, Prop, Vue, Watch} from "vue-property-decorator";
import {AdresTuru, AdresTuruLabel} from "@/enum/AdresTuru";
import EnumPicker from "@/components/common-inputs/EnumPicker.vue";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";
import {MasrafTuru, MasrafTuruLabel} from "../../enum/MasrafTuru";
@Component({
    components: {EnumPicker}
})
export default class MasrafTuruPicker extends Mixins(SimpleInputMixin) {
    turler = MasrafTuru;
    turlerLabel = MasrafTuruLabel;
}














import {Component, Mixins} from "vue-property-decorator";
import EnumPicker from "@/components/common-inputs/EnumPicker.vue";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";
import {TahsilatTuru, TahsilatTuruLabel} from "@/enum/TahsilatTuru";

@Component({
    components: {EnumPicker}
})
export default class TahsilatTuruPicker extends Mixins(SimpleInputMixin) {
    turler = TahsilatTuru;
    turlerLabel = TahsilatTuruLabel;
}

























































import {Component, Mixins, Prop, Vue} from "vue-property-decorator";
import Dates from "@/components/inputs/Dates.vue";
import {CezaiSartEntity} from "@/entity/AlacakBelgeler/CezaiSartEntity";
import {SozlesmeEntity} from "@/entity/AlacakBelgeler/SozlesmeEntity";
import ProtestoIhtar from "@/components/takip/Belgeler/ProtestoIhtar.vue";
import ParaBirimi from "@/components/inputs/ParaBirimi.vue";
import {mixins} from "vue-class-component";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import Faizler from "@/components/takip/Belgeler/Faizler.vue";
import Tutar from "@/components/inputs/Tutar.vue";

@Component({
    components: {Tutar, Faizler, ParaBirimi, Dates,ProtestoIhtar}
})
export default class CezaiSart extends Mixins(ObjectInputMixin) {

        autoUpdate:boolean=false;
}









































import {Component, Prop, Vue} from "vue-property-decorator";
import TahsilatForm from "@/components/anakart/tahsilat/TahsilatForm.vue";
import DeleteButton from "@/components/common-inputs/DeleteButton.vue";
import FormDialogButton from "@/components/common-inputs/FormDialogButton.vue";
import {TebligatEntity} from "@/entity/TebligatEntity";
import TebligatForm from "@/components/anakart/tebligat/TebligatForm.vue";
import {BorcluEntity} from "@/entity/BorcluEntity";
import {TebligatSekli, TebligatSekliLabel} from "@/enum/TebligatSekli";
import {TebligatDurumu, TebligatDurumuLabel} from "@/enum/TebligatDurumu";

@Component({
    components: {TebligatForm, FormDialogButton, DeleteButton, TahsilatForm}
})
export default class TebligatList extends Vue {
    @Prop({})
    borclu!: BorcluEntity;
    val!: Array<TebligatEntity>;
    localValue: Array<TebligatEntity> = Array.isArray(this.val) ? this.val : Array.isArray(this.borclu.tebligatlar) ? this.borclu.tebligatlar : [];
    TebligatSekli= TebligatSekliLabel;
    TebligatDurumu = TebligatDurumuLabel;

    search: string = "";
    headers = [
        {
            text: "Gönderim Tarihi",
            align: "start",
            sortable: true,
            value: "tebligatGonderimTarihi"
        },
        {text: "Durumu", value: "tebligatDurumu"},
        {text: "Şekli", value: "tebligatSekli"},
        {text: "İtiraz Var Mı", value: "itirazVarMi"},
        {text: "İşlemler", value: "actions", sortable: false}

    ];
    mounted()
    {
        console.log("borclu :",this.borclu)
        console.log("tebligatlar :",this.localValue)
    }
}




















































































import {Component, Mixins, Prop, Watch} from "vue-property-decorator";
import Dates from "@/components/inputs/Dates.vue";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import TebligatTuruPicker from "@/components/pickers/TebligatTuruPicker.vue";
import GonderilenPicker from "@/components/pickers/GonderilenPicker.vue";
import {BorcluEntity} from "@/entity/BorcluEntity";
import {KisiEntity} from "@/entity/KisiEntity";
import TebligatSekliPicker from "@/components/pickers/TebligatSekliPicker.vue";
import AdresSelect from "@/components/comps/selects/AdresSelect.vue";
import {TebligatDurumu} from "@/enum/TebligatDurumu";
import TebligatAlanSelect from "@/components/comps/selects/TebligatAlanSelect.vue";
import {TebligatAlanEntity} from "@/entity/TebligatAlanEntity";


@Component({
    components: {
        TebligatAlanSelect,
        AdresSelect,
        TebligatSekliPicker, GonderilenPicker, TebligatTuruPicker, Dates
    }
})
export default class TebligatForm extends Mixins(ObjectInputMixin) {
    @Prop({})
    borclu!: BorcluEntity;

    TebligatDurumu = TebligatDurumu;
    mounted()
    {
       if (!this.localValue.tebligatAlan){
           this.localValue.tebligatAlan = new TebligatAlanEntity();
       }
    }
    @Watch('localValue.tebligatAlan')
    onLocalValueChanged() {
        console.log("id: ",this.localValue.tebligatAlan.id)
        if (this.localValue.tebligatAlan.id === 1) {
            if (this.borclu.kisiKurum != null && (this.borclu.kisiKurum as KisiEntity).soyad != undefined) {
                this.localValue.teslimEdilenAdSoyad = this.borclu.kisiKurum?.ad + " " + (this.borclu.kisiKurum as KisiEntity).soyad;
            } else if (this.borclu.kisiKurum != null && (this.borclu.kisiKurum as KisiEntity).soyad == undefined) {
                this.localValue.teslimEdilenAdSoyad = this.borclu.kisiKurum?.ad;
            }
        }
    }
}

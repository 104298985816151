









































































import {Component, Prop, Vue} from "vue-property-decorator";
import TahsilatList from "@/components/anakart/tahsilat/TahsilatList.vue";
import {TakipEntity} from "@/entity/TakipEntity";
import {TakipListResponseProvider} from "@/services/TakipService";
import {TahsilatEntity} from "@/entity/TahsilatEntity";
import MasrafList from "@/components/anakart/masraf/MasrafList.vue";
import FormDialogButton from "@/components/common-inputs/FormDialogButton.vue";
import {VekaletUcretOranEntity} from "@/entity/VekaletUcretOranEntity";
import HarcForm from "@/components/anakart/HarcForm.vue";
import {MasrafEntity} from "@/entity/MasrafEntity";
import {VekaletUcretDonemEntity} from "@/entity/VekaletUcretDonemEntity";
import {VekaletDonemListResponseProvider} from "@/services/VekaletDonemService";
import {IlamliAlacakKalemiEntity} from '@/entity/AlacakBelgeler/IlamliAlacakKalemiEntity';
import {NafakaEntity} from '@/entity/AlacakBelgeler/NafakaEntity';


@Component({
    components: {HarcForm, FormDialogButton, MasrafList, TahsilatList}
})
export default class HesapDokum extends Vue {
    @Prop()
    item!: IlamliAlacakKalemiEntity;
    //item!: Array<IlamliAlacakKalemiEntity> | Array<AlacakKalemiEntity>;


    @Prop()
    tahsilatlar!: Array<TahsilatEntity>;

    degis: boolean = false;
    autoUpdate: boolean = true;
    takip: TakipEntity = TakipListResponseProvider().data[0];
    belgelerToplami: number = 0;
    takipTutari: number | null = 0;
    takipToplami: number = 0;
    bsmv1: number = 0;
    bsmv2: number = 20;
    kkdf1: number = 0;
    kkdf2: number = 10;
    ihtar: number = 50;
    diger: number = 100;
    kdv: number = 77;
    protesto: number = 150;
    masrafToplam: number = 0;
    vekaletUcreti: number = 0;
    harcToplam: number = 0;
    faiz: number = 0;
    uzunluk = 0;
    //  uzunluk=this.items.nafakalar.length;
    tutar: number | null = 0;
    kalan: number = 0;
    tahsilatToplam: number = 0;
    bsmvOlsun: boolean = true;
    fonOlsun: boolean = true;
    AKBirlestir: boolean = false;
    dialogTahsilat: boolean = false;
    dialogMasraf: boolean = false;
    vekaletDonem: VekaletUcretDonemEntity = VekaletDonemListResponseProvider().data[0];

    mounted() {
        // this.IlamsizTekilDetayliHesap();
        this.IlamliNafakaTekilDetayliHesap();
    }


    IlamsizTekilDetayliHesap() {
        console.log("takibe eklenecek tutar:", this.item.takibeEklenecekTutar);

        this.takipTutari = 0;
        this.tutar = this.item.tutar;
        this.takipTutari = this.item.takibeEklenecekTutar;
        if (this.item.takibeEklenecekTutar != null)
            this.faiz = this.item.takibeEklenecekTutar / 100 * 9;
        this.bsmv1 = this.bsmv1 + (this.faiz / 100 * 5);
        this.kkdf1 = this.kkdf1 + (this.faiz / 100 * 15);
    }


    IlamliNafakaTekilDetayliHesap() {
        console.log("uzunlukkkk :", this.uzunluk);
        this.item.nafakalar.forEach((nafaka: NafakaEntity) => {
            if (nafaka.tutar != null) {
                this.takipTutari = nafaka.tutar;
                this.faiz = this.faiz + (nafaka.tutar / 100 * 9);
            }
        })
    }


    index = 0;

    VekaletUcretiHesapla() {
        let tempUstSinir;
        this.vekaletDonem.oranlar?.forEach((vekaletOran: VekaletUcretOranEntity) => {
            if (this.vekaletDonem.oranlar != null) {
                tempUstSinir = this.vekaletDonem.oranlar[this.index-1].ustSinir;
                if(tempUstSinir===null)
                    tempUstSinir = 99999999;
                if (this.takip.takipTalebi?.takipTarihi != null && this.vekaletDonem.baslangicTarihi != null && this.vekaletDonem.bitisTarihi != null && vekaletOran.ustSinir != null && vekaletOran.muvekkilOran != null && this.vekaletDonem.oranlar[this.index - 1].ustSinir != null) {
                    if (this.takip.takipTalebi?.takipTarihi > this.vekaletDonem.baslangicTarihi && this.takip.takipTalebi?.takipTarihi < this.vekaletDonem.bitisTarihi && this.takipToplami > tempUstSinir && this.takipToplami < vekaletOran.ustSinir) {
                        this.vekaletUcreti = this.takipToplami * vekaletOran.muvekkilOran / 100;
                    }
                }
            }
        })
        this.index++;
    }

    MasrafHesapla() {
        if (this.takip.masraflar != null) {
            this.takip.masraflar.forEach((masraf: MasrafEntity) => {
                if (masraf.masrafTutari != null)
                    this.masrafToplam = this.masrafToplam + masraf.masrafTutari;
            })
        }
    }

    HarcHesapla() {
        let pesinHarcTutari = 0;
        let tahsilHarcTutari = 0;
        if (this.takip.harclar?.pesinHarcOrani != null && this.takip.harclar?.tahsilHarciOrani != null && this.takip.harclar?.idareHarci != null && this.takip.harclar?.vekaletHarci != null && this.takip.harclar?.basvuruHarci != null) {
            pesinHarcTutari = this.takipToplami * this.takip.harclar?.pesinHarcOrani / 100;
            tahsilHarcTutari = this.takipToplami * this.takip.harclar?.tahsilHarciOrani / 100;
            this.harcToplam = tahsilHarcTutari + pesinHarcTutari + this.takip.harclar?.vekaletHarci + this.takip.harclar?.basvuruHarci + this.takip.harclar?.idareHarci;
        }
    }
}





















































































import {Component, Mixins, Prop, Vue} from "vue-property-decorator";
import ProtestoIhtar from "@/components/takip/Belgeler/ProtestoIhtar.vue";
import {SenetEntity} from "@/entity/AlacakBelgeler/SenetEntity";
import Dates from "@/components/inputs/Dates.vue";
import ParaBirimi from "@/components/inputs/ParaBirimi.vue";
import {mixins} from "vue-class-component";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import Faizler from "@/components/takip/Belgeler/Faizler.vue";
import BankaBilgileri from "@/components/takip/Belgeler/BankaBilgileri.vue";
import Tutar from "@/components/inputs/Tutar.vue";

@Component({
    components: {Tutar, Faizler, ParaBirimi, Dates, ProtestoIhtar,BankaBilgileri}
})
export default class Senet extends Mixins(ObjectInputMixin) {

    autoUpdate:boolean=false;

}


































import {Component, Mixins, Prop, Vue, Watch} from "vue-property-decorator";
import Dates from "@/components/inputs/Dates.vue";
import {KiraEntity} from "@/entity/AlacakBelgeler/KiraEntity";
import Tutar from "@/components/inputs/Tutar.vue";
import ParaBirimi from "@/components/inputs/ParaBirimi.vue";
import {IlamliAlacakTuru} from "@/entity/IlamliAlacakTuru";
import {mixins} from "vue-class-component";
import { IlamliAlacakTuruListResponseProvider } from '@/services/AlacakBelgeleri/IlamliAlacakTuruService';
import {BorcluListResponseProvider} from "@/services/BorcluService";



import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";

@Component({
    components: {ParaBirimi, Tutar, Dates}
})
export default class AlacaklarForm extends Mixins(ObjectInputMixin) {


    ilamTurleri = IlamliAlacakTuruListResponseProvider().data;
    filteredIlamTurleri = IlamliAlacakTuruListResponseProvider().data;
    borclular = BorcluListResponseProvider().data;

     @Prop({})
     ilamTur!: string;

    @Watch('localValue.ilamTuru')
    onLocalValueChanged() {
        this.filteredIlamTurleri = this.ilamTurleri.filter(item => item.ilamTuru === this.localValue.ilamTuru);
    }

    mounted() {
         console.log("Ne geldi ilam forma: ", this.ilamTurleri);
         console.log("Ne geldi  ",this.localValue);
        if (this.localValue.alacakTuru === null) {
            this.localValue.alacakTuru = new IlamliAlacakTuru;
        }
    }

}

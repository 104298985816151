
























































































import {Component, Mixins, Watch} from "vue-property-decorator";
import Dates from "@/components/inputs/Dates.vue";
import {BorcluListResponseProvider} from "@/services/BorcluService";
import {ParaBirimiListResponseProvider} from "@/services/ParaBirimiService";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import TahsilatTuruPicker from "@/components/pickers/TahsilatTuruPicker.vue";
import {HarcOraniResponseProvider} from "@/services/HarcOraniService";
import ParaBirimi from "@/components/inputs/ParaBirimi.vue";
import Tutar from "@/components/inputs/Tutar.vue";
import FormDialogButton from "@/components/common-inputs/FormDialogButton.vue";
import {TahsilatTuru} from "@/enum/TahsilatTuru";

@Component({
    components: {FormDialogButton, Tutar, ParaBirimi, TahsilatTuruPicker, Dates}
})
export default class TahsilatForm extends Mixins(ObjectInputMixin) {
    TahsilatTuru = TahsilatTuru;
    borclular = BorcluListResponseProvider().data;
    paraBirimleri = ParaBirimiListResponseProvider().data;
    harcOranlari = HarcOraniResponseProvider().data;
    gecici: any | null = {tahsilHarci: 0, cezaeviHarci: 0};

    get TahsilHarci() {
        const temp1 = this.localValue.tutar/100 * this.localValue.harcOrani.value;
        this.gecici.tahsilHarci = temp1;
        return temp1;
    }

    get CezaeviHarci() {
         this.gecici.cezaeviHarci = this.localValue.tutar * 0.02;
        return this.gecici.cezaeviHarci;
    }

    @Watch('localValue')
    onValueChange() {
        this.localValue.netTutar = this.localValue.tutar - (this.gecici.tahsilHarci + this.gecici.cezaeviHarci);
    }
}




























import {Component, Mixins, Prop, Watch} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import ParaBirimi from "@/components/inputs/ParaBirimi.vue";
import Tutar from "@/components/inputs/Tutar.vue";
import {TahsilHarciEntity} from "@/entity/TahsilHarciEntity";
import {TahsilHarciResponseProvider} from "@/services/TahsilHarciService";
import Dates from "../../inputs/Dates.vue";


@Component({
    components: {Dates, Tutar, ParaBirimi}
})
export default class SeksenDokuzForm extends Mixins(ObjectInputMixin) {
    @Prop()
    label!: string;

}

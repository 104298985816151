

























































import {Component, Prop, Vue} from "vue-property-decorator";
@Component({
    components: {}
})
export default class Aidat extends Vue {

}

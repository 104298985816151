

































































































































































































































import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import AlacakKalemiEntity from "@/entity/AlacakBelgeler/AlacakKalemiEntity";
import TahsilatList from "@/components/anakart/tahsilat/TahsilatList.vue";
import {TakipEntity} from "@/entity/TakipEntity";
import {TakipListResponseProvider} from "@/services/TakipService";
import {TahsilatEntity} from "@/entity/TahsilatEntity";
import MasrafList from "@/components/anakart/masraf/MasrafList.vue";
import FormDialogButton from "@/components/common-inputs/FormDialogButton.vue";
import {BorcluEntity} from '@/entity/BorcluEntity';
import {VekaletUcretOranEntity} from "@/entity/VekaletUcretOranEntity";
import HarcForm from "@/components/anakart/HarcForm.vue";
import {MasrafEntity} from "@/entity/MasrafEntity";
import AlacakKalemleriSecenekForm from "@/components/anakart/AlacakKalemleriSecenekForm.vue";
import {VekaletUcretDonemEntity} from "@/entity/VekaletUcretDonemEntity";
import {IlamliAlacakKalemiEntity} from '@/entity/AlacakBelgeler/IlamliAlacakKalemiEntity';
import {NafakaEntity} from '@/entity/AlacakBelgeler/NafakaEntity';


import {VekaletDonemListResponseProvider} from "@/services/VekaletDonemService";
import VekaletDonemList from "@/components/tercihler/VekaletDonemList.vue";
import {VekaletUcretleriTaslakListProvider} from "@/services/VekaletUcretleriTaslaklarService";
import HesapTarihi from "@/components/inputs/HesapTarihi.vue";
import ViDialog from "@/components/common-inputs/Dialog.vue";
import AlacakKalemleriForm from "@/components/takip/form/AlacakKalemleriForm.vue";
import AlacakKalemiDetayForm from "@/components/takip/form/AlacakKalemiDetayForm.vue";

@Component({
        components: {
            AlacakKalemiDetayForm,
            AlacakKalemleriForm,
            ViDialog,
            HesapTarihi,
            VekaletDonemList, HarcForm, FormDialogButton, MasrafList, TahsilatList, AlacakKalemleriSecenekForm
        }
    }
)
export default class HesapDokum extends Vue {
    @Prop()
    value!: Array<IlamliAlacakKalemiEntity> | Array<AlacakKalemiEntity>;


    vekaletUcretleriOrnegi = VekaletUcretleriTaslakListProvider().data[0];
    @Prop()
    tahsilatlar!: Array<TahsilatEntity>;
    @Prop({default: 'takipTalebi'})
    type?: 'takipTalebi' | 'kart';
    @Prop()
    details: boolean = true;
    degis: boolean = false;
    @Prop()
    toolbar!: boolean;
    autoUpdate: boolean = true;
    takip: TakipEntity = TakipListResponseProvider().data[0];
    belgelerToplami: number = 0;
    takipToplami: number = 0;
    bsmv1: number = 0;
    hesapTarihi: boolean=true;
    bsmv2: number = 20;
    kkdf1: number = 0;
    kkdf2: number = 10;
    ihtar: number = 0;
    diger: number = 0;
    kdv: number = 77;
    protesto: number = 0;
    masrafToplam: number = 0;
    vekaletUcreti: number = 0;
    harcToplam: number = 0;
    faiz: number = 0;
    toplam: number = 0;
    kalan: number = 0;
    tahsilatToplam: number = 0;
    bsmvOlsun: boolean = true;
    fonOlsun: boolean = true;
    AKBirlestir: boolean = false;
    dialogTahsilat: boolean = false;
    dialogMasraf: boolean = false;
    vekaletDonem: VekaletUcretDonemEntity = VekaletDonemListResponseProvider().data[0];

    mounted() {
        console.log("value :", this.value);
        console.log("type :", this.type);
        if (this.type == 'takipTalebi') {
            this.details = false;
            console.log("det :", this.details);
        }
        console.log("gelen şey", this.toolbar)
        // this.Hesapla();
        this.IlamliHesapla();


    }

    @Watch('value')
    onValueChange() {
        // this.Hesapla();
        this.IlamliHesapla();
    }

    IlamliHesapla() {
        console.log("aktif");
        this.belgelerToplami = 0;
        this.faiz = 0;
        this.toplam = 0;
        this.takipToplami = 0;
        this.diger = 0;

        (this.value as IlamliAlacakKalemiEntity[]).forEach((ilamliAlacakKalemi: IlamliAlacakKalemiEntity) => {
            if (ilamliAlacakKalemi.nafakalar !== null) {
                console.log("ilamli nafaka : ", ilamliAlacakKalemi.nafakalar);
                ilamliAlacakKalemi.nafakalar.forEach((nafaka: NafakaEntity) => {
                    if (nafaka.tutar != null) {
                        this.belgelerToplami = this.belgelerToplami + nafaka.tutar;
                        this.faiz = this.faiz + (nafaka.tutar / 100 * 9);
                    }
                })
            }
        })

        this.takipToplami = this.belgelerToplami + this.faiz + this.diger;
        this.VekaletUcretiHesapla();
        this.MasrafHesapla();
        this.HarcHesapla();
        this.toplam = this.takipToplami + this.masrafToplam + this.harcToplam + this.vekaletUcreti + this.kdv;
        this.kalan = this.toplam - this.tahsilatToplam;
    }

    SeeHideDetails() {
        this.details = !this.details;
    }
    HesapTarihiGoster(){
        this.hesapTarihi=!this.hesapTarihi;
    }

    Hesapla() {
        console.log("aktif");
        this.belgelerToplami = 0;
        this.bsmv1 = 0;
        this.kkdf1 = 0;
        this.faiz = 0;
        this.toplam = 0;
        this.takipToplami = 0;

        this.value.forEach((alacakKalemi: AlacakKalemiEntity) => {
            if (alacakKalemi.takibeEklenecekTutar === null || alacakKalemi.takibeEklenecekTutar === 0) {
                alacakKalemi.takibeEklenecekTutar = alacakKalemi.tutar;
            }
            if (alacakKalemi.takibeEklenecekTutar !== null) {
                this.belgelerToplami = this.belgelerToplami + alacakKalemi.takibeEklenecekTutar;
                this.faiz = this.faiz + (alacakKalemi.takibeEklenecekTutar / 100 * 9);
                this.bsmv1 = this.bsmv1 + (this.faiz / 100 * 5);
                this.kkdf1 = this.kkdf1 + (this.faiz / 100 * 15);
            }
        })
        this.takip.borclular?.forEach((borclu: BorcluEntity) => {
            borclu.tahsilatlar?.forEach((tahsilat: TahsilatEntity) => {
                if (tahsilat.tutar !== null) {
                    this.tahsilatToplam = this.tahsilatToplam + tahsilat.tutar;
                }
            })
        })
        if (this.bsmvOlsun == false)
            this.bsmv1 = 0;
        if (this.fonOlsun == false)
            this.kkdf1 = 0;
        this.takipToplami = this.bsmv1 + this.belgelerToplami + this.faiz + this.kkdf1 + this.diger + this.protesto + this.ihtar;
        this.VekaletUcretiHesapla();
        this.MasrafHesapla();
        this.HarcHesapla();
        this.toplam = this.takipToplami + this.kkdf2 + this.bsmv2 + this.masrafToplam + this.harcToplam + this.vekaletUcreti + this.kdv;
        this.kalan = this.toplam - this.tahsilatToplam;
    }

    index = 0;

    VekaletUcretiHesapla() {
        let tempUstSinir;
        this.vekaletDonem.oranlar?.forEach((vekaletOran: VekaletUcretOranEntity) => {
            if (this.vekaletDonem.oranlar != null) {
                tempUstSinir = this.vekaletDonem.oranlar[this.index - 1].ustSinir;
                if (tempUstSinir === null)
                    tempUstSinir = 99999999;
                if (this.takip.takipTalebi?.takipTarihi != null && this.vekaletDonem.baslangicTarihi != null && this.vekaletDonem.bitisTarihi != null && vekaletOran.ustSinir != null && vekaletOran.muvekkilOran != null && this.vekaletDonem.oranlar[this.index - 1].ustSinir != null) {
                    if (this.takip.takipTalebi?.takipTarihi > this.vekaletDonem.baslangicTarihi && this.takip.takipTalebi?.takipTarihi < this.vekaletDonem.bitisTarihi && this.takipToplami > tempUstSinir && this.takipToplami < vekaletOran.ustSinir) {
                        this.vekaletUcreti = this.takipToplami * vekaletOran.muvekkilOran / 100;
                    }
                }
            }
        })
        this.index++;
    }

    MasrafHesapla() {
        if (this.takip.masraflar != null) {
            this.takip.masraflar.forEach((masraf: MasrafEntity) => {
                if (masraf.masrafTutari != null)
                    this.masrafToplam = this.masrafToplam + masraf.masrafTutari;
            })
        }
    }

    HarcHesapla() {
        let pesinHarcTutari = 0;
        let tahsilHarcTutari = 0;
        if (this.takip.harclar?.pesinHarcOrani != null && this.takip.harclar?.tahsilHarciOrani != null && this.takip.harclar?.idareHarci != null && this.takip.harclar?.vekaletHarci != null && this.takip.harclar?.basvuruHarci != null) {
            pesinHarcTutari = this.takipToplami * this.takip.harclar?.pesinHarcOrani / 100;
            tahsilHarcTutari = this.takipToplami * this.takip.harclar?.tahsilHarciOrani / 100;
            this.harcToplam = tahsilHarcTutari + pesinHarcTutari + this.takip.harclar?.vekaletHarci + this.takip.harclar?.basvuruHarci + this.takip.harclar?.idareHarci;
        }
    }
}



















import {Component, Mixins, Prop, Watch} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import ParaBirimi from "@/components/inputs/ParaBirimi.vue";
import Tutar from "@/components/inputs/Tutar.vue";
import {TahsilHarciEntity} from "@/entity/TahsilHarciEntity";
import {TahsilHarciResponseProvider} from "@/services/TahsilHarciService";
import Dates from "../../inputs/Dates.vue";
import {YedieminEntity} from "@/entity/Haciz/YedieminEntity";
import AdresForm from "@/components/comps/forms/AdresForm.vue";


@Component({
    components: {AdresForm, Dates, Tutar, ParaBirimi}
})
export default class YedieminForm extends Mixins(ObjectInputMixin) {

}

import {IlceEntity} from './IlceEntity';
import {AdresTuru} from "@/enum/AdresTuru";
import { KisiKurumEntity } from './KisiKurumEntity';

export class AdresEntity{
    id: number | null = null;
    adresTuru: AdresTuru | null = null;
    ilce: IlceEntity = new IlceEntity();
    adresDetayi: string | null = null;
    postaKodu: number | null = null;
    kisiKurum: KisiKurumEntity | null = null;
    birincil: boolean = false;
}


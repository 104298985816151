


















import {Component, Mixins, Prop, Vue, Watch} from "vue-property-decorator";
import Dates from "@/components/inputs/Dates.vue";
import {KiraEntity} from "@/entity/AlacakBelgeler/KiraEntity";
import Tutar from "@/components/inputs/Tutar.vue";
import ParaBirimi from "@/components/inputs/ParaBirimi.vue";
import {mixins} from "vue-class-component";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";

@Component({
    components: {ParaBirimi, Tutar, Dates}
})
export default class NafakaVeDigerForm extends Mixins(ObjectInputMixin) {


}

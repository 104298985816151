














import {Component, Mixins, Prop, Vue} from "vue-property-decorator";
import NoterIslemForm from "@/components/comps/forms/NoterIslemForm.vue";
import MuvekkilList from "@/components/vekalet/MuvekkilList.vue";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import ItemArrayManager from "@/components/common-inputs/ItemArrayManager.vue";
import KisiKurumForm from "@/components/kisi/kisiKurumForm.vue";
import {TableHeader} from "@/interface/TableHeader";

@Component({
    components: {KisiKurumForm, ItemArrayManager, MuvekkilList, NoterIslemForm}
})
export default class vekaletForm extends Mixins(ObjectInputMixin) {
    muvekkilHeaders: Array<TableHeader> =[
        {text:"Ad",value:"ad"},
        {text:"Soyad",value:"soyad"},

    ]
    localValue={
        muvekkiller:[]
    }

}












































































import {Component, Mixins, Prop} from "vue-property-decorator";
import ProtestoIhtar from "@/components/takip/Belgeler/ProtestoIhtar.vue";
import {SozlesmeEntity} from "@/entity/AlacakBelgeler/SozlesmeEntity";
import Dates from "@/components/inputs/Dates.vue";
import ParaBirimi from "@/components/inputs/ParaBirimi.vue";
import {mixins} from "vue-class-component";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import AdresTuruPicker from "@/components/pickers/AdresTuruPicker.vue";
import IlPicker from "@/components/pickers/IlPicker.vue";
import IlcePicker from "@/components/pickers/IlcePicker.vue";
import Faizler from "@/components/takip/Belgeler/Faizler.vue";
import Tutar from "@/components/inputs/Tutar.vue";

@Component({
    components: {Tutar, Faizler, IlcePicker, IlPicker, AdresTuruPicker, ParaBirimi, Dates, ProtestoIhtar}
})
export default class Sozlesme extends Mixins(ObjectInputMixin) {


}



























































import {Component, Vue} from "vue-property-decorator";
import TakipTalebiList from "@/components/takip/list/TakipTalebiList.vue";
import BorclularList from "@/components/takip/list/BorclularList.vue";
import AlacaklilarList from "@/components/takip/list/AlacaklilarList.vue";
import TerekeList from "@/components/takip/list/TerekeList.vue";
import IlamBilgileriList from "@/components/takip/list/IlamBilgileriList.vue";
import BorcunSebebiList from "@/components/takip/list/BorcunSebebiList.vue";
import TakipYoluList from "@/components/takip/list/TakipYoluList.vue";
import KiraBilgileriList from "@/components/takip/list/KiraBilgileriList.vue";
import {TakipEntity} from "@/entity/TakipEntity";
import {TakipListResponseProvider} from "@/services/TakipService";
import AlacakKalemleriForm from "@/components/takip/form/AlacakKalemleriForm.vue";
import ItemArrayManager from "@/components/common-inputs/ItemArrayManager.vue";
import TahsilatList from "@/components/anakart/tahsilat/TahsilatList.vue";
import MasrafList from "@/components/anakart/masraf/MasrafList.vue";
import MuvekkilList from "@/components/vekalet/MuvekkilList.vue";
import AlacakKalemleriList from "@/components/takip/list/AlacakKalemleriList.vue";
import HesapDokum from "@/components/anakart/HesapDokum.vue";
import {PersonelListResponseProvider} from "@/services/PersonelService";
import FormDialogButton from "@/components/common-inputs/FormDialogButton.vue";
import PersonelBilgiForm from "@/components/buro/personel/PersonelBilgiForm.vue";
import YetkiliAvukat from "@/components/anakart/YetkiliAvukat.vue";
import YeniTakipVekaletListesi from "@/components/takip/YeniTakipVekaletListesi.vue";
import TebligatList from "@/components/anakart/tebligat/TebligatList.vue";
import {TahsilatEntity} from "@/entity/TahsilatEntity";
import {BorcluEntity} from "@/entity/BorcluEntity";
import TaksitlendirmeForm from "@/components/anakart/taksitlendirme/TaksitlendirmeForm.vue";
import CariHareketList from "@/components/comps/lists/CariHareketList.vue";

@Component({
    components: {
        CariHareketList,
        TaksitlendirmeForm,
        TebligatList,
        YeniTakipVekaletListesi,
        YetkiliAvukat,
        PersonelBilgiForm,
        FormDialogButton,
        HesapDokum,
        AlacakKalemleriList,
        MuvekkilList,
        MasrafList,
        TahsilatList,
        ItemArrayManager,
        AlacakKalemleriForm,
        KiraBilgileriList,
        TakipYoluList,
        BorcunSebebiList,
        IlamBilgileriList, TerekeList, AlacaklilarList, BorclularList, TakipTalebiList
    }
})
export default class TakipDetay extends Vue {
    item: TakipEntity | null = null;
    tahsilatlar: Array<TahsilatEntity> = [];
    avukat = PersonelListResponseProvider().data[0];

    mounted() {
        let index: number = parseInt(this.$route.params.id) - 1;
        this.item = TakipListResponseProvider(6).data[index];
        console.log("Takip Talebi, Servisten Gelen DATA", this.item);
        this.item?.borclular.forEach((borclu: BorcluEntity) => {
            borclu.tahsilatlar.forEach((tahsilat: TahsilatEntity) => {
                this.tahsilatlar.push(tahsilat);
            })
        });
        console.log("Tahsilatlar: ", this.tahsilatlar);
    }
}
















































import {Component, Mixins} from "vue-property-decorator";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import Dates from "@/components/inputs/Dates.vue";

@Component({
    components: {Dates}
})
export default class TaksitTutarinaGoreTaksitlendirmeForm extends Mixins(ObjectInputMixin) {

    periyodlar=[
        "Gün",
        "Ay",
        "Yıl"
    ]
    taksitSayisi=0
    taksitPeriyodu=1
}






























import {Component, Prop, Vue} from "vue-property-decorator";
import FormDialogButton from "@/components/common-inputs/FormDialogButton.vue";
import {PersonelListResponseProvider} from "@/services/PersonelService";
import {PersonelTuru} from "@/enum/PersonelTuru";
import {AvukatPersonelEntity} from "@/entity/AvukatPersonelEntity";


@Component({
    components: {FormDialogButton}
})
export default class AvukatSec extends Vue {

    avukatlar = PersonelListResponseProvider().data.filter((value, index) => value.personelTuru === PersonelTuru.avukat);

    AvukatSecF(secili:AvukatPersonelEntity){
        this.$emit('succes',secili);
    }
    Headers=[
        {
            text: "Adı",
            align: "start",
            sortable: false,
            value: "ad"
        },

        {text: "TC Kimlik No / Vergi Kimlik No", value: "tc"},
        {text: "İşlemler", value: "actions", sortable: false}
    ]
}


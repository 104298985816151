





























import {Component, Mixins} from "vue-property-decorator";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import {mixins} from "vue-class-component";@Component({
    components: {}
})
export default class TebligatAlanForm extends Mixins(ObjectInputMixin) {
    mounted(){
        this.localValue.isimGirilecek = true;
    }
    onSubmit() {
            this.$emit('input',this.localValue);
            this.$emit('close');
    }
    cancel(){
        this.$emit('close');
    }
};





































































import {Component, Mixins, Prop} from "vue-property-decorator";
import ProtestoIhtar from "@/components/takip/Belgeler/ProtestoIhtar.vue";
import {CekEntity} from "@/entity/AlacakBelgeler/CekEntity";
import Faizler from "@/components/takip/Belgeler/Faizler.vue";
import BankaBilgileri from "@/components/takip/Belgeler/BankaBilgileri.vue";
import Dates from "@/components/inputs/Dates.vue";
import ParaBirimi from "@/components/inputs/ParaBirimi.vue";
import Tutar from "@/components/inputs/Tutar.vue";
import IlPicker from "@/components/pickers/IlPicker.vue";
import {mixins} from "vue-class-component";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";

@Component({
    components: {IlPicker, Tutar, ParaBirimi, Dates, Faizler, ProtestoIhtar,BankaBilgileri}
})
export default class Cek extends Mixins(ObjectInputMixin) {

       autoUpdate:boolean=false;
}














import {Component, Mixins} from "vue-property-decorator";

import EnumPicker from "@/components/common-inputs/EnumPicker.vue";
import {mixins} from "vue-class-component";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";
import {TebligatDurumu, TebligatDurumuLabel} from "../../enum/TebligatDurumu";

@Component({
    components: {EnumPicker}
})
export default class TebligatTuruPicker extends Mixins(SimpleInputMixin) {
    turler = TebligatDurumu;
    turlerLabel = TebligatDurumuLabel;
}

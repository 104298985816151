var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.listwithtext===false)?_c('v-card',{staticClass:"mx-auto"},[_c('v-toolbar',{attrs:{"dense":"","color":"light-blue darken-4","dark":""}},[_c('v-toolbar-title',[_vm._v(" Alacaklı Bilgileri ")]),_c('v-spacer'),_c('form-dialog-button',{attrs:{"dark":"","color":"green","title":"Alacaklı Bilgileri","icon":"mdi-plus"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var formData = ref.formData;
var onInput = ref.onInput;
return [_c('alacakli-form',{on:{"input":onInput},model:{value:(formData),callback:function ($$v) {formData=$$v},expression:"formData"}})]}}],null,false,1642635689)})],1),(_vm.items!=null)?_c('v-data-table',{attrs:{"dense":"","headers":_vm.headers,"items":_vm.items,"hide-default-footer":"","no-data-text":"Alacaklı eklemek için + butonunu kullanabilirsiniz."},scopedSlots:_vm._u([{key:"item.kisiKurum.ad",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"seedetails",on:{"click":function($event){return _vm.detay(item)}}},[_vm._v(" "+_vm._s(_vm.$helper.kisiKurumAd(item.kisiKurum))+" ")])]}},{key:"item.kisiKurum.tc",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$helper.tcknOrVergiNo(item.kisiKurum))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"color":"light-blue darken-4","icon":""},on:{"click":function($event){return _vm.detay(item)}}},[_c('v-icon',[_vm._v("mdi-arrow-right-bold-box")])],1),_c('form-dialog-button',{attrs:{"title":"Alacak Bilgileri","icon":"mdi-pencil"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var formData = ref.formData;
var onInput = ref.onInput;
return [_c('alacakli-form',{attrs:{"baslik":"Müvekkil"},on:{"input":onInput},model:{value:(formData),callback:function ($$v) {formData=$$v},expression:"formData"}})]}}],null,true),model:{value:(_vm.items[ _vm.items.indexOf(item) ]),callback:function ($$v) {_vm.$set(_vm.items,  _vm.items.indexOf(item) , $$v)},expression:"items[ items.indexOf(item) ]"}}),_c('delete-button')]}}],null,false,2034654900)}):_c('v-skeleton-loader',{attrs:{"type":"table"}})],1):_c('v-card',[_c('form-dialog-button',{attrs:{"dark":"","color":"green","title":"Alacaklı Bilgileri","icon":"mdi-plus"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var formData = ref.formData;
var onInput = ref.onInput;
return [_c('alacakli-form',{on:{"input":onInput},model:{value:(formData),callback:function ($$v) {formData=$$v},expression:"formData"}})]}}])}),_vm._l((_vm.items),function(item){return [_c('a',{staticStyle:{"color":"darkgrey"},on:{"click":function($event){return _vm.kkfAc(item)}}},[_vm._v(" "+_vm._s(item.ad)+", ")])]})],2)}
var staticRenderFns = []

export { render, staticRenderFns }






















































































import {Component, Mixins, Prop} from "vue-property-decorator";
import ProtestoIhtar from "@/components/takip/Belgeler/ProtestoIhtar.vue";
import {KiraKontratiEntity} from "@/entity/AlacakBelgeler/KiraKontratiEntity";
import Dates from "@/components/inputs/Dates.vue";
import ItemArrayManager from "@/components/common-inputs/ItemArrayManager.vue";
import ParaBirimi from "@/components/inputs/ParaBirimi.vue";
import KiraForm from "@/components/takip/Belgeler/KiraForm.vue";
import {ItemArrayManagerTableHeader, TableHeader} from "@/interface/TableHeader";
import {mixins} from "vue-class-component";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import AdresTuruPicker from "@/components/pickers/AdresTuruPicker.vue";
import IlPicker from "@/components/pickers/IlPicker.vue";
import IlcePicker from "@/components/pickers/IlcePicker.vue";
import Faizler from "@/components/takip/Belgeler/Faizler.vue";
import {AdresEntity} from "@/entity/AdresEntity";
import Tutar from "@/components/inputs/Tutar.vue";



@Component({
    components: {
        Faizler,
        IlcePicker,
        IlPicker, AdresTuruPicker, KiraForm, ParaBirimi, ItemArrayManager, Dates, ProtestoIhtar,Tutar
    }
})
export default class KiraKontrati extends Mixins(ObjectInputMixin) {

    autoUpdate:boolean=false;

    kiralarHeaders: Array<ItemArrayManagerTableHeader> = [
        {
            value: "kiraBaslangicTarihi",
            text: "Tarihten",
            renderer: (row) => this.$helper.tarihStr(row.kiraBaslangicTarihi)
        },
        {
            value: "kiraBitisTarihi", text: "Tarihe", renderer: (row) => {
                return this.$helper.tarihStr(row.kiraBitisTarihi);
            }
        },
        {value: "tutar", text: "Tutar", renderer: (row)=> this.$helper.tutarStr(row.tutar) },
        {value: "paraBirimi.ad", text: "Para Birimi"},
    ];

    mounted(){
        console.log("localValue --- ",this.localValue);
    }
}

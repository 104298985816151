










































import {Component, Mixins} from "vue-property-decorator";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import ParaBirimi from "@/components/inputs/ParaBirimi.vue";
import Tutar from "@/components/inputs/Tutar.vue";
import Dates from "../../inputs/Dates.vue";



@Component({
    components: { Dates, Tutar, ParaBirimi}
})
export default class YuzUcDavetiyeVeTebligZarfiForm extends Mixins(ObjectInputMixin) {
    hacizSaati:string="10:45";
    adet:number=1;
    olusturulsun!:boolean;
}























































import {Component, Prop, Vue} from "vue-property-decorator";
import DeleteButton from "@/components/common-inputs/DeleteButton.vue";

import KisiKurum from "@/components/inputs/KisiKurum.vue";
import KisiKurumForm from "@/components/kisi/kisiKurumForm.vue";
import FormDialogButton from "@/components/common-inputs/FormDialogButton.vue";
import AlacakliForm from "@/components/takip/form/AlacakliForm.vue";
import {AlacakliEntity} from "@/entity/AlacakliEntity";



@Component({
    components: {AlacakliForm, FormDialogButton, KisiKurumForm, KisiKurum, DeleteButton}
})


export default class AlacaklilarList extends Vue {

    @Prop({})
    items!: AlacakliEntity[];

    @Prop({})
    listwithtext!: boolean;

    headers = [
        {
            text: "Adı",
            align: "start",
            sortable: false,
            value: "muvekkil.kisiKurum.ad"
        },

        {text: "TC Kimlik No / Vergi Kimlik No", value: "muvekkil.kisiKurum.tc"},
        {text: "İşlemler", value: "actions" , width:150, sortable: false}

    ];


    detay(item:AlacakliEntity) {
        console.log("muvekkil kim: ",item);
        this.$router.push('/muvekkil/' + item.muvekkil?.kisiKurum?.id?.toString() + '/detay');
    }

    mounted() {
        console.log("muvekkiller: ", this.items);
    }

}
